import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Niehl: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Niehl,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Niehl"
          d="M641.3,479.8c0.5,1.5,1.2,2.4,1.5,4.5c1.8,9.9,1.5,12.4-0.1,23.8c-0.8,5.6-3.5,11.8-3.5,11.8L612,518l-4-14 l-22-8l-30,2l-30-4l4-16l8-6l4-6l-8-18l6-4l-36-36l-14,4v-12l-10-2l-32-66l-0.3-0.2c5.4-9.1,4.3-7.5,4.3-17.8l44-10l16,8l-4,16 l24-6l36,10l-6.5,10.9c-3.6,6.1-6.3,12.6-8.1,19.5v0c-2.9,11.7-3.7,23.8-2.2,35.7l0,0c0.5,3.9,0.8,9.3,3.7,13 c0,0,11.1,15.6,19.6,20.5c11.1,6.4,23,12.9,34.6,18.7c9.5,4.7,18.1,11.2,25.3,18.9l0.3,0.3C637.6,472.5,639.8,476,641.3,479.8 L641.3,479.8z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 493.2367 367.3532)"
        >
          Niehl
        </text>
      </g>
    </>
  );
});
